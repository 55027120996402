import * as React from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "../app/ag-grid-theme.css" // Theme
import { useIsFetching, useQuery } from "@tanstack/react-query";
import { useUser, useLeaderboard, useFleetData, useVesselList, useAuth } from '../app/zustore';
import { fetchData } from '../app/QueryHandler';
import { FleetActivity } from './activity-widget';
import { Skeleton, Tooltip, useMediaQuery } from "@mui/material";
import { AccessTimeOutlined, AttachMoneyOutlined, Co2Outlined, ElectricBoltOutlined, NumbersOutlined, RouteOutlined } from '@mui/icons-material';
// import { CalendarHeatmap } from './cal-heatmap';

export function numberWithCommas(x) {
    if (x>1_000){
        return (Math.round(x*100/1000)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'k'
    } else if (x>1_000_000){
        return (Math.round(x*100/1_000_000)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'m'
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const LeaderBoard=({isMobile})=>{
    const {leaderboard, updateLeaderboard} = useLeaderboard()
    const { status } = useQuery(['leaderboard'])
    const vesselList = useVesselList(s=>s.vesselList)
    const user = useUser(s=>s.user)
    const token = useAuth((state)=>state.token);

    const [colDefs, setColDefs] = React.useState([
        {
            field: 'name', 
            headerName: 'Name', 
            tooltipField: 'name', 
            headerTooltip:'Vessel name Column',
            filter: 'agSetColumnFilter'
        }, 
        {
            field: 'odometer', 
            headerName: 'Odometer (miles)', 
            // tooltipField: 'odometer', 
            headerTooltip:'Odometer (miles)',
            filter: 'agNumberColumnFilter'
        }, 
        {
            field: 'cumulative_battery_kWh', 
            headerName: 'kWh used', 
            headerTooltip:'Cumulative kWh used',
            filter: 'agNumberColumnFilter'
        }, 
        {
            field: 'cumulative_battery_pct', 
            headerName: 'battery %', 
            tooltipField: 'cumulative_battery_pct', 
            headerTooltip:'Cumulative Battery % used',
            filter: 'agNumberColumnFilter'
        }, 
        {
            field: 'motor_hours', 
            headerName: 'Motor hours', 
            tooltipField: 'motor_hours', 
            headerTooltip:'Hours',
            filter: 'agNumberColumnFilter'
        }, 
        {
            field: 'avg_mi_per_kWh', 
            headerName: 'Efficiency', 
            tooltipField: 'Efficiency', 
            headerTooltip:'Avg Efficiency mi/kWh',
            filter: 'agNumberColumnFilter'
        }, 
        {
            field: 'latest_trip_start_time', 
            headerName: 'latest trip start time', 
            tooltipField: 'latest_trip_start_time', 
            headerTooltip:'Latest Trip Start Time Column',
            filter: 'agDateColumnFilter',
        }, 
        {
            field: 'num_trips', 
            headerName: '# Trips', 
            tooltipField: 'num_trips', 
            minWidth: 80,
            headerTooltip:'Number of Trips Column',
            filter: 'agNumberColumnFilter'
        },
    ]);

    const defaultColDef = React.useMemo(() => {
        return {
          flex: 1,
          minWidth: 120,
          filter: 'agTextColumnFilter',
          menuTabs: ['filterMenuTab'],
        };
    }, []);

    // const leaderboardQuery = useQuery({
    //     queryKey: ['leaderboard'],
    //     queryFn: async () => {
    //         const {status, body, type} = await fetchData({ method: 'GET', resource: 'trips/leaderboard', token: token, params: null });
    //         if (status===204) throw new Error('No data returned')
    //         if (status===403) throw new Error('403: Unauthorized')
            
    //         const data = body
    //         // console.log(data)
    //         if (data==='') throw new Error('No data returned')
    //         return data;
    //     },
    //     enabled: true,
    //     refetchInterval: 1000 * 60 * 15, // 30 seconds
    //     retry: 2,
    //     onSuccess: (data) => {
    //       if (data) {            
    //         console.groupCollapsed('leaderboard query');            
    //         console.table(data);  
    //         console.table(vesselList) 
    //         // const updatedLeaderboard = [...leaderboard, ...data]
    //         // const newData = data.filter(item => !leaderboard.some(existingItem => existingItem.vessel_id === item.vessel_id)); // Filter out any items that already exist in the existing data
    //         // const mergedData = [...leaderboard, ...newData]
            
    //         data = data.map(v=>{
    //             let vessel = vesselList.find(vessel=> vessel.vessel_id===v.vessel_id) || {vessel_name:v.vessel_id, battery:12}                
    //             return ({ ...v, name:vessel?.vessel_name, battery:vessel.battery })
    //             }
    //         )
    //         console.table(data)
    //         updateLeaderboard(data)
    //         console.groupEnd();
    //       }
    //     }
    // });

    const info_widgets = [
        {
            label: "Fleet Distance", 
            units: "mi", 
            tooltip: 'The total distance traveled by Vessels in your fleet during trips',
            icon: <RouteOutlined className='icon'/>, 
            value: numberWithCommas(Math.round(leaderboard.map((a)=>a?.odometer||0).reduce((a,b)=>a+b, 0))) ?? '--'
        },
        {
            label: "Motor Hours", 
            units: "hrs", 
            tooltip: 'The motor hours accumulated by Vessels in your fleet during trips',
            icon: <AccessTimeOutlined className='icon'/>, 
            value: numberWithCommas(Math.round(leaderboard.map((a)=>a?.motor_hours||0).reduce((a,b)=>a+b, 0))) ?? '--'
        },
        {
            label: "Trips", 
            units: "", 
            tooltip: 'The total number of trips completed by Vessels in your fleet',
            icon:  <NumbersOutlined className='icon'/>, 
            value: numberWithCommas(Math.round(leaderboard.map((a)=>a?.num_trips||0).reduce((a,b)=>a+b, 0))) ?? '--'
        },
        {
            label: "CO2eq Abatement", 
            units: "lbs", 
            tooltip: 'The total equivalent CO2 weight greenhouse gas emmissions which would have been emitted by equivalents vessels to those in your fleet during trips',
            icon: <Co2Outlined className='icon'/>, 
            value: numberWithCommas(Math.round(leaderboard.map((a)=>a?.total_co2eq_prevented_lbs||0).reduce((a,b)=>a+b, 0)/1000))+'k' ?? '--'
        },
        {
            label: "Energy Usage", 
            units: "MWh", 
            tooltip: 'The total electrical energy used by Vessels in your fleet during trips',
            icon: <ElectricBoltOutlined className='icon'/>, 
            value: numberWithCommas(Math.round(leaderboard.map((a)=>a?.cumulative_battery_kWh||0).reduce((a,b)=>a+b, 0)/10)/100) ?? '--'
        },
        {
            label: "Fuel savings", 
            units: "$", 
            tooltip: 'The difference between the estimated cost of the electrical energy used by your fleet, and the estimated number of gallons which would have been required by equivalent vessels to complete identical trips',
            icon: <AttachMoneyOutlined className='icon'/>, 
            value: numberWithCommas(Math.round(leaderboard.map((a)=>a?.total_fuel_savings||0).reduce((a,b)=>a+b, 0))) ?? '--'
        }
    ]

    if (leaderboard.length==0 && (status.isFetching||status.isLoading||status.isError)){
        if (isMobile){
            return (
                <div className={`leaderboard ${status.isError ? 'error' : 'loading'}`} >
            
                    <div className='leaderboard-info-area'>
                        <div className='leaderboard-widget-area'>       
                            {
                                info_widgets.map((widget, index) => (
                                    <LeaderboardInfoWidget widget={widget} key={index}/>
                                ))
                            }
                        </div>

                        <Skeleton variant='rounded' height={'80%'} width={"80%"} className='fleetActivity loading'/>
                    </div>

                    {/* <Skeleton variant='rounded' height={340} width={"80%"} className='leaderboard-table loading'/> */}
                </div>
            )
        }
        return (
        <div className={`leaderboard ${status.isError ? 'error' : 'loading'}`} >
            
            <div className='leaderboard-info-area'>
                <div className='leaderboard-widget-area'>       
                    {
                        info_widgets.map((widget, index) => (
                            <LeaderboardInfoWidget widget={widget} key={index}/>
                        ))
                    }
                </div>

                <Skeleton variant='rounded' height={'80%'} width={"80%"} className={`fleetActivity ${status.isError ? 'error' : 'loading'}`}/>
            </div>

            <Skeleton variant='rounded' height={340} width={"80%"} className={`leaderboard-table ${status.isError ? 'error' : 'loading'}`}/>
        </div>
                
        )
    }
    return (
        <div className='leaderboard' >
            
            <div className='leaderboard-info-area'>
                <div className='leaderboard-widget-area'>       
                    {
                        info_widgets.map((widget, index) => (
                            <LeaderboardInfoWidget widget={widget} key={index}/>
                        ))
                    }
                </div>

                <FleetActivity isMobile={isMobile}/>
                {/* <CalendarHeatmap /> */}
            </div>
                
            {
            !isMobile &&
            <div 
                className={
                    "ag-theme-alpine-dark leaderboard-table" 
                }>
                <AgGridReact 
                    pagination={true}
                    rowHeight={40}
                    enableCellChangeFlash={true}
                    paginationPageSize={isMobile?6:10}
                    paginationPageSizeSelector={[6,10, 20, 50]}
                    rowData={leaderboard} 
                    defaultColDef={defaultColDef}
                    columnDefs={colDefs} />
            </div>
            }
        </div>
    );
}

export const LeaderboardInfoWidget = ({widget}) => {
    return (
        <Tooltip title={widget.tooltip} arrow>
        <div className='leaderboard-info-widget' >
            <div className='header'>
                <span className='label'>{widget.label}</span>
                {widget.icon}
            </div>
            <div className='value-area'>
                <span className='value'>{widget.value || '--'}</span>
                <span className='units'>{widget.units}</span>
            </div>
        </div>
        </Tooltip>
    )
}