import { 
    Box, 
    Modal, 
    CardHeader,
    TextField,
    Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth, useVesselList } from 'app/zustore';
import { fetchData } from 'app/QueryHandler';
import PublishIcon from '@mui/icons-material/Publish';
import { enqueueSnackbar } from 'notistack';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/vessels/manage/rename')({
    component: RenameVessel
  })

export function RenameVessel ({  }) {
    const [newVesselName, setVesselName] = useState('')
    const token = useAuth(state=>state.token)
    const vesselList = useVesselList(state=>state.vesselList);
    const { vessel_id } = Route.useSearch()
    const vessel = vesselList.find(v=>v.vessel_id==vessel_id)
    const vessel_name = vessel?.vessel_name || '';

    const handleInputChange = (event) => {  
        setVesselName(event.target.value);
    };

    useEffect(() => {
        console.log(vessel_id, vessel)
    }, []);
        
    const handleCreateGroup = async () => {
        if (newVesselName.length>0){
            console.groupCollapsed('NewGroup-handleSubmit');
            // setStatus('loading')
            let requestBody = {
                vessel_name:newVesselName, 
                vessel_id:vessel_id
            };
        
            console.table(requestBody)
            console.log(JSON.stringify(requestBody))
            console.groupEnd();
            
            const {status, response} = await fetchData({method:'PUT', resource:'vessel-info', token:token, body:requestBody})
            if (status==400){
                enqueueSnackbar('Failed to create Group', {variant: 'error'})
            } else if (status==201||status==200){
                enqueueSnackbar('Group Created', {variant: 'success'})
                window.location.reload()
                
            }
            console.table(response)
        }
    }

    const header = () => {
        if (!vessel_name) return <h1>Rename a Vessel</h1>
        else return <h1>Rename <span>{`${vessel_name}`}</span> {newVesselName && 'to'} <span>{newVesselName && newVesselName}</span></h1>
    }

    return (
        <div className='manage-page-1-col' >
                {header()}
                <div className='manage-node-outer' >
                    
                    <TextField                         
                        label={"New name of vessel"}
                        className='text-field'
                        placeholder={`Enter a new name`}
                        variant="outlined"
                        defaultValue={''}
                        onChange={(value) => handleInputChange(value)}
                    />

                    <button 
                        className='confirm'
                            onClick={()=>{handleCreateGroup()}}     
                    >
                    Submit <PublishIcon />
                    </button>
                </div>
            </div>
    )
}