import { Box, SwipeableDrawer, Typography } from "@mui/material"
import { useGroups, useNotifications, useUser, zuPage } from "./zustore";
import { Notification } from "./notification";
import { ChevronRight, Menu } from "@mui/icons-material";
import { useState } from "react";
import { SignOutButton } from "utils/signOutButton";


export const SideBar = ({ isMobile })=>{
    const { user_info } = useUser(state=>({user_info:state.user}))
    const notifications = useNotifications(s=>s.notifications)
    const addNotification = useNotifications(s=>s.addNotification)    
    const groups = useGroups(s=>s.groups)
    const { page, setPage, leftSidebarOpen, toggleLeftSidebar, rightSidebarOpen, toggleRightSidebar, sidebarView, setSidebarView } = zuPage(s=>({
        page:s.page, 
        setPage:s.setPage, 
        leftSidebarOpen:s.leftSidebarOpen,
        toggleLeftSidebar:s.toggleLeftSidebar,
        rightSidebarOpen:s.rightSidebarOpen,
        toggleRightSidebar:s.toggleRightSidebar,
        sidebarView: s.sidebarView, 
        setSidebarView: s.setSidebarView
    }))

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
  
        
        // Keep the mobile drawer open when swiped to the right
        if (open) {
          if (anchor === 'right'){
            toggleRightSidebar(true)
            if (sidebarView==null){
              setSidebarView('profile')
            }
          } else if (anchor==='left'){
            toggleLeftSidebar(true)
          }
        } else {
          if (anchor === 'right'){
            toggleRightSidebar(false)
          } else if (anchor==='left'){
            toggleLeftSidebar(false)
          }
        }
    }; 

    const SideBarContent = ()=>{
      if (sidebarView==null) return null
      else if (sidebarView=='profile') {
        return (
          <div className='SideBarContent'>
            <div className='profile'>
              <Typography variant="h4" sx={{ color: 'var(--light-grey-2)', fontSize: '1.2rem', textAlign:'start', marginLeft:'8px' }}>
                Signed in as:
              </Typography>
              <Typography variant="h3" sx={{ color: 'var(--light-grey-1)', fontSize: '1.5rem', textAlign:'start', marginLeft:'8px' }}>
                {user_info?.user_name}
              </Typography>
            </div>

            <div className='groups'>
              {
              (user_info.nodes) ? 
              <Box className="groups-inner-content">
                <Typography variant="h4" sx={{ color: 'var(--light-grey-3)', fontSize: '1.2rem', textAlign:'start', marginLeft:'8px' }}>
                  You belong to the following groups:
                </Typography>
                <ul>

                {
                  Object.keys(user_info.nodes).map((node_id, index)=>{
                    let group = groups.find(g=>g.node_id==node_id)
                    return <li key={index} className='group'>{group.node_name}</li>
                  })
                }
                </ul>
              </Box>
              :
              <Box className="groups-inner-content">
                <h2 style={{color:'white', marginTop:4, textTransform:'bold'}} >You do not belong to any groups.</h2>
                <Typography sx={{color:'white', }} >Access to boats is provided through groups and you must belong to one in order to see any boats</Typography>
                <Typography sx={{color:'white', }}>If you have already purchased a boat and are the primary owner please contact your sales representative.</Typography>
                <Typography sx={{color:'white', marginBottom:4}}>Otherwise please ask the primary owner of your boat to invite you to their Fleet.</Typography>   
              </Box>
            }
            </div>             

              <SignOutButton expanded={true}/>

          </div>
      )} else {
        return (
          <div className='SideBarContent'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: '10px',
                width:'100%'
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: 'var(--light-grey-3)', fontSize: '1.2rem', textAlign:'start', marginLeft:'8px' }}
              >
                Notifications:
              </Typography>

              <div className='notifications'>
                {notifications.map((n, i)=>{
                  return (
                    <Notification key={i} notification={n}/>
                  )
                }
                )}
              </div>
              

            </div>
          </div>
      )}
    }

    if (isMobile){
      return (
        <SwipeableDrawer
          className='swipe'
          swipeAreaWidth={32}
          anchor="right"
          open={rightSidebarOpen}
          onOpen={toggleDrawer('right', true)}
          onClose={toggleDrawer('right', false)}
        >
          <Box className='SideBar'>
            {SideBarContent()}
          </Box>
        </SwipeableDrawer>
      )
    } else {
      return (
      <Box 
        className={`SideBar right ${rightSidebarOpen?'open':''}`}
        onClick={()=>{sidebarView==null&&setSidebarView('profile')}}
        >
        <button onClick={()=>{sidebarView==null?setSidebarView('profile'):setSidebarView(null)}} style={{backgroundColor:'var(--dark-grey-3)', borderRadius:0, width:'100%', display:'flex', justifyContent:'space-around', border:'none', alignItems:'center', height:'3.5vh', margin:0, padding:0}}>
          {
            sidebarView==null ?
            <Menu sx={{color:'var(--light-grey-3)', transform:'rotate(90deg)'}} />
            :
            <ChevronRight sx={{color:'var(--light-grey-3)'}} />}
          {sidebarView!=null && <span style={{color:'var(--light-grey-3)', fontSize:'1em'}}>Close Sidebar</span>}
        </button>
        {SideBarContent()}
      </Box>
      )
    }   
  }