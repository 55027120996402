import { getDistance } from '../utils/utilityMethods'

const DENSITY = 1024; // kg/m^3
const GRAVITY = 9.81 // m/s^2
const USABLE_BATT_PCT = 0.8 // pct of battery capacity which is usable
const BATT_MODULE_CAP = 6.6 // kWh in each battery module

const activeDataPoint = (row, isBud) => {
    if (isBud) {
        // determine if bud device is active
        if (row['on_water'] && row['telemetry_data.inst_speed']>0.5) {
            return true
        } return false
    } else {
        if (row['motor_data.0.fwd_spin_time']!==null && row['motor_data.0.rev_spin_time']!==null) {
            return true
        } return false
    }
}

function getMinValue(array, attr) {
    console.log(array.filter(item => item[attr]!==null))
    return Math.min(...array.filter(item => item[attr]!==null).map(item => item[attr]));
}

export const utc_time_to_string = (time) =>{
    return new Date(time+'Z').toLocaleString()
}

const process_faults = (fault_arr)=>{
    if (fault_arr===null || fault_arr===undefined || fault_arr===''){
        return null
    }

    if (typeof(fault_arr)==='number'){
        return [fault_arr]
    } else if (typeof(fault_arr)==='string'){
        // console.log(fault_arr.split(',').map(f=>parseInt(f)))
        return fault_arr.split(',').map(f=>parseInt(f))
    }
}

export const count_rows_per_vessel = (data)=>{
    let id_counts = {}
    if (data==undefined || data==null){
        return {}
    }
    data.map(r=>r.vessel_id).forEach(r=>{
        if (!id_counts[r]){
            id_counts[r] = 1;
        } else {
            id_counts[r] += 1;
        }
    })
    console.log(id_counts)
    return id_counts
}

export function processAnalysisData(mergedData, vesselInfo, datasource) {        
    // console.table(mergedData.slice(0, 20));
    const processedData = [...mergedData];
    console.groupCollapsed('Data Processing Script')
    console.log(vesselInfo, mergedData.length, processedData.length)

    // Adding calculated fields to the retrieved Data
    // calculate and accumulate distance
    let totalBatteryUsage = 0;
    let totalDistance = 0;
    const L = vesselInfo.length ? vesselInfo.length : 20; // estimate the median length of boats in our fleet
    const Disp = vesselInfo.Displacement ? vesselInfo.Displacement : 1200; // estimate the median displacement of boats in our fleet
    const Disp_vol = Disp / DENSITY; // the volumetric displacement
    const BattCap_kWh = vesselInfo.battery * BATT_MODULE_CAP * USABLE_BATT_PCT; // usable kWh in the battery
    const BattCap_kW_s = BattCap_kWh * 3600;
    let distance, usage;    
        
    let fwd_spin_time = getMinValue(processedData, 'motor_data.0.fwd_spin_time');
    let rev_spin_time = getMinValue(processedData, 'motor_data.0.rev_spin_time');

    const calculate_efficiency = (speed, power) => {
        if (Math.abs(power)<0.5 || speed<0.5){
            return 0
        } else {
            return speed / Math.abs(power);
        }
    }

    const calculate_powertrain_loss = (epower, mpower) => {
        if (Math.abs(epower)<0.5 || Math.abs(mpower)<0.5){
            return 0
        } else {
            return 100 - (Math.abs(mpower) / Math.abs(epower))*100;
        }
    }

    const rows_per_vessel = count_rows_per_vessel(mergedData);

    processedData[0] = {
        ...processedData[0],
        sample_time_str: utc_time_to_string(processedData[0].time),
        distance: 0,
        index: 0,
        total_distance_km: 0,
        battery_usage: 0,
        total_battery_usage: 0,
        mi_per_kwh: calculate_efficiency(processedData[0]?.['telemetry_data.inst_speed']||0, processedData[0]?.['battery_data.electrical_power']),
        MPGe: calculate_efficiency(processedData?.[0]?.['telemetry_data.inst_speed']||0, processedData[0]?.['battery_data.electrical_power'])*33.7,
        drivetrain_loss: calculate_powertrain_loss(processedData[0]?.['battery_data.electrical_power'] , processedData[0]?.['motor_data.0.power']),
        electric_power_abs: Math.abs(processedData[0]?.['battery_data.electrical_power']),
        fwd_spin_time_min: fwd_spin_time / 60,
        fwd_spin_time_hrs: fwd_spin_time / 3600,
        rev_spin_time_min: rev_spin_time / 60,
        rev_spin_time_hrs: rev_spin_time / 3600,
        total_spin_time_min: (fwd_spin_time + rev_spin_time) / 60,
        total_spin_time_hrs: (fwd_spin_time + rev_spin_time) / 3600,
        active: activeDataPoint(processedData[0], vesselInfo.isParticleDevice),
        ecu_faults: process_faults(processedData[0]['ecu_data_0_faults']),
        charge_voltage: processedData[0]['obc_data_0_battery_voltage'],
        charge_current: processedData[0]['obc_data_0_battery_current'],
    };
    console.log(processedData[0].active)
    // let flattened_fault_rows = [];
    let efficiency = 0;
    for (let i = 1; i < processedData.length; i++) {
        const isActive = activeDataPoint(processedData[i], vesselInfo.isParticleDevice);
        processedData[i]['active'] = isActive;
        if (isActive){ // this might not suffice
            
            if (processedData[i]['telemetry_data.distance']!==null) { // check if the message contains the distance calculated by pi
                distance = processedData[i]['telemetry_data.distance'];    // meters            
            } else if ((processedData[i]['motor_data.0.system_state']===5 && Math.abs(processedData[i]['motor_data.0.velocity'])>=50) || vesselInfo.isParticleDevice) {
                const lat1 = processedData[i-1]['telemetry_data.latitude'];
                const lon1 = processedData[i-1]['telemetry_data.longitude'];
                const lat2 = processedData[i]['telemetry_data.latitude'];
                const lon2 = processedData[i]['telemetry_data.longitude'];
                distance = getDistance(lat1, lon1, lat2, lon2); // Meters               
                // if (!vesselInfo.isParticleDevice) console.log(i, processedData[i], "has no pi distance but motor was spinning", processedData[i]['telemetry_data.distance'], distance);
            } else {
                distance = 0;
            }
            
            efficiency = calculate_efficiency(processedData[i]?.['telemetry_data.inst_speed'], processedData[i]?.['battery_data.electrical_power'])
            const soc1 = processedData[i-1]['battery_data.battery_soc'];
            const soc2 = processedData[i]['battery_data.battery_soc'];
            usage = soc1 - soc2;
            usage = usage>0 ? usage : 0;
            totalBatteryUsage += usage; 
            fwd_spin_time = processedData[i]['motor_data.0.fwd_spin_time'];
            rev_spin_time = processedData[i]['motor_data.0.rev_spin_time'];        

            // Flatten ecu_data.0.faults array
            // const faults = process_faults(processedData[i]['ecu_data.0.faults']);
            // if (faults!==null){
            //     faults.forEach((fault, index) => {
            //         if (index==0){
            //             processedData[i].ecu_faults = fault;
            //         } else {
            //             flattened_fault_rows.push({
            //                 ...processedData[i],
            //                 index: i,
            //                 ecu_faults: fault,
            //                 sample_time_str: utc_time_to_string(processedData[i].time+1)
            //             });
            //         }
            //     });
            // }
        } else {
            processedData[i]['telemetry_data.distance'] = 0;
            usage = 0;
            distance = 0;
            efficiency = 0;
        }
        totalDistance = totalDistance + (distance / 1000); // Kilometer

        // console.log(i, processedData[i]['ecu_data.0.faults'])
        if(processedData[i]['ecu_data.0.faults']!==null){
        }
        processedData[i] = {
            ...processedData[i],
            sample_time_str: utc_time_to_string(processedData[i].time),
            total_battery_usage: totalBatteryUsage,
            motor_rpm_abs: Math.abs(processedData[i]['motor_data.0.velocity']),
            fwd_spin_time_min: fwd_spin_time / 60,
            fwd_spin_time_hrs: fwd_spin_time / 3600,
            rev_spin_time_min: rev_spin_time / 60,
            rev_spin_time_hrs: rev_spin_time / 3600,
            total_spin_time_min: (fwd_spin_time + rev_spin_time) / 60,
            total_spin_time_hrs: (fwd_spin_time + rev_spin_time) / 3600,
            kWh: BattCap_kWh * (processedData[i]['battery_data.battery_soc'] / 100),
            'telemetry_data.distance': distance,
            mi_per_kwh: efficiency,
            MPGe: efficiency*33.7,
            drivetrain_loss: calculate_powertrain_loss(processedData[i]?.['battery_data.electrical_power'] , processedData[i]?.['motor_data.0.power']),
            electric_power_abs: Math.abs(processedData[i]?.['battery_data.electrical_power']),
            distance_miles: (distance*0.6213712/1000),
            total_distance_km: totalDistance,
            total_distance_mi: (totalDistance*0.6213712),
            'battery_usage': usage,
            'Speed_m/s': processedData[i]['telemetry_data.inst_speed'] * 0.44704,
            Froude: processedData[i]['Speed_m/s'] / Math.sqrt(GRAVITY * L),
            FNV: processedData[i]['Speed_m/s'] / Math.sqrt(GRAVITY * Disp_vol^(1/3)),
            'Power/Vel': processedData[i]['Speed_m/s'] > 0 ? processedData[i]['motor_data.0.power'] / processedData[i]['Speed_m/s'] : 0,
            Range_m: processedData[i]['Power/Vel'] !== 0 ? BattCap_kW_s / processedData[i]['Power/Vel'] : 0,
            Range_miles: processedData[i]['Range_m'] * 0.0006213712,
            Range_miles_inst: processedData[i]['Range_miles'] * (processedData[i]['battery_data.battery_soc'] / 100),
            Endurance_s: processedData[i]['motor_data.0.power'] > 0.5 ? (BattCap_kW_s / processedData[i]['motor_data.0.power']) : 0,
            Endurance_min: processedData[i]['Endurance_s'] / 60,
            Endurance_hr_inst: processedData[i]['motor_data_0_power'] > 0.5 ? (processedData[i]['kWh'] / processedData[i]['motor_data_0_power']) : 0,
            Endurance_min_inst: processedData[i]['Endurance_min'] * (processedData[i]['battery_data_battery_soc'] / 100),
            // ecu_faults: process_faults(processedData[i]['ecu_data_0_faults']),
            charge_voltage: processedData[i]['obc_data_0_battery_voltage'],
            charge_current: processedData[i]['obc_data_0_battery_current'],
            index: i
            };
        };
    console.log(processedData)
    processedData.sort((a, b) => {return new Date(a.time) - new Date(b.time);})
    console.log(processedData)
    // console.table(temp1.map(r=>({time:r.time, fault:r.ecu_faults})))
    console.groupEnd();
    return processedData
}