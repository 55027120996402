import * as React from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "../app/ag-grid-theme.css" // Theme
import { useUser, useLeaderboard, zuPage, useVesselList, useAuth, useVessel, useSelect, useGroups } from '../app/zustore';
import { useLocation, useNavigate } from '@tanstack/react-router';


export const VesselQuickSelector=({ })=>{
    const { setTab } = zuPage(state=>({setTab:state.setTab}));
    const navigate = useNavigate()
    const location = useLocation();
    const vessel_id = useVessel(s=>s.vessel_id)
    const groups = useGroups(s=>s.groups)
    const vesselList = useVesselList(s=>s.vesselList)
    const setVessel = useVessel(s=>s.setVessel)
    const setSelected = useSelect(state=>state.setSelected);
    const selected = useSelect(state=>state.selected);
    const user = useUser(s=>s.user)
    const token = useAuth((state)=>state.token);
    const [gridApi, setGridApi] = React.useState(null)

    const vessels = React.useMemo(()=>{
        const mapped_vessels = vesselList.map(vessel=>({...vessel, group:groups.find(g=>g.node_id==Object.keys(vessel.node)[0])?.node_name}))
        console.log(mapped_vessels)
        return mapped_vessels
    },[])
    
    const [colDefs, setColDefs] = React.useState([
        {
            field: 'vessel_name', 
            headerName: 'Name', 
            tooltipField: 'name', 
            headerTooltip:'Vessel name Column',
            checkboxSelection: true,
            minWidth: 180,
            filter: 'agSetColumnFilter'
        }, 
        {
            field: 'device_id', 
            headerName: 'Device Id', minWidth: 180,
            filter: 'agSetColumnFilter'
        }, 
        {
            field: 'group', 
            headerName: 'Group', minWidth: 180,
            filter: 'agSetColumnFilter'
        }, 
        {
            field: 'make', 
            headerName: 'Make', 
            tooltipField: 'odometer', 
            headerTooltip:'Manufacturer',
            filter: 'agSetColumnFilter'
        }, 
        {
            field: 'model', 
            headerName: 'Model', 
            tooltipField: 'Model', 
            filter: 'agSetColumnFilter'
        }, 
        {
            field: 'battery', 
            headerName: 'Battery', 
            filter: 'agNumberColumnFilter'
        }, 
        {
            field: 'length', 
            headerName: 'Length', 
            filter: 'agNumberColumnFilter'
        }, 
    ]);

    const defaultColDef = React.useMemo(() => {
        return {
          flex: 1,
          minWidth: 120,
          filter: 'agTextColumnFilter',
          menuTabs: ['filterMenuTab'],
        };
    }, []);

    React.useEffect(() => {
        selectRowByVesselId()
    }, [vessel_id, vesselList]);
        

    const onRowSelected = React.useCallback((event) => {
        // if (event.node.isSelected()){
            // console.log('onRowSelected', event.node)
        const node = event.api.getSelectedNodes()[0]?.data;
        // console.log(node, vessel_id)

        if (node 
            && vessel_id !== node.vessel_id 
            && event.type=='selectionChanged' 
            && (event.source=='rowClicked'||event.source=='checkboxSelected')
        ){ 
            console.groupCollapsed('VesselQuickSelector onRowSelected', event, typeof(event));
            console.log(location)
            
            setVessel(node.vessel_id, node.vessel_name)
            setSelected(vesselList.findIndex(v=>v.vessel_id===node.vessel_id))
            console.groupEnd()
            const path = (location.pathname == `/vessels/select`) ? ((node.vessel_name.search('Dyno')>=0) ? '/vessels/dyno' : '/vessels/live') : location.pathname
            navigate({
                to: path, 
                search:{vessel_id: node.vessel_id},
            })
        }
    }, []);

    const onGridReady =(params)=>{
        setGridApi(params.api);        
      }

      function selectRowByVesselId() {
        if (gridApi!==null){
            gridApi.forEachNode(function(rowNode) {
            if (rowNode.data.vessel_id === vessel_id) {
                rowNode.setSelected(true);
            } else {
                rowNode.setSelected(false);
            }
            });
        }
    }

   

    return (
        <div className='vessel-quick-selector'  style={{height:'100%', width:'90%'}}>     
            <h1>Select a Vessel</h1>           
            <div className={"ag-theme-alpine-dark table" }>
                <AgGridReact 
                    pagination={true}
                    rowHeight={30}
                    enableCellChangeFlash={true}
                    paginationPageSize={15}
                    paginationPageSizeSelector={[10, 15, 20, 50]}
                    rowData={vessels} 
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    rowSelection='single'
                    domLayout={'autoHeight'}
                    onSelectionChanged={onRowSelected}
                    columnDefs={colDefs} 
                />
            </div>
        </div>
    );
}