import { createFileRoute, Link, Outlet, redirect, useLocation, useNavigate } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { LocalizationProvider } from '@mui/x-date-pickers';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useMediaQuery } from '@mui/material';
import ErrorBoundary from "../utils/ErrorBoundary";
import logo from "../images/01_Flux Only.svg";
import { useAuth, useFleetData, useNotifications, useUser, useVesselList, zuPage } from '../app/zustore';
import { useResizeDetector } from 'react-resize-detector';
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';
import { fetchData } from '../app/QueryHandler';
import { checkIsAuthenticated,  } from '.';
import { useSnackbar } from 'notistack';
import { useEffect,  } from 'react';
import { subscribeUser } from '../app/push-notifications';
import { NavBar } from 'app/navbar';
import { SideBar } from 'app/sidebar';
import { FluxDataProvider } from 'app/flux-data-provider';

// const env = process.env.REACT_APP_ENV;
const TOP_BAR_HEIGHT = 40;


export const Route = createFileRoute('/_authenticated')({
    loader: AuthLoader,
    component: AuthLayout,
})

/**
 * This Component runs prior to the AuthLayout Component rendering.
 * It requests the data from Nodes, Users, and Vessel-Info in parallel,
 * and awaits the results from all 3 before returning and allowing the main component to render.
 */
async function AuthLoader({ context, location}){
    const setAuth = useAuth.getState().setAuth;
    const setIsAuthenticated= useAuth.getState().setIsAuthenticated;
    
    try {
        console.log("Authenticated Route Loader Component running")
        const user = await getCurrentUser();
        let { idToken } = (await fetchAuthSession()).tokens ?? {};
        const JWT = idToken.toString();
        setAuth(JWT, user.userId);
      } catch (error) {
        setIsAuthenticated(false);
        console.log('Root Loader is not authenticated')        
        return false
      }  
      return true;  
};

function AuthLayout(){
  const isMobile = useMediaQuery('(max-width:600px)');
  const { height, ref } = useResizeDetector();
  const { fleetData, updateFleetData } = useFleetData();
  const {vesselList, setVesselList } = useVesselList();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = useAuth(s=>s.token)
  const notifications = useNotifications(s=>s.notifications)
  const addNotification = useNotifications(s=>s.addNotification)
  const { user_info, setUser } = useUser(state=>({user_info:state.user, setUser:state.setUser}))
  
  const navigate = useNavigate();
  const location = useLocation();
  const { page, setPage, leftSidebarOpen, toggleLeftSidebar, rightSidebarOpen, toggleRightSidebar, sidebarView, setSidebarView } = zuPage(s=>({
      page:s.page, 
      setPage:s.setPage, 
      leftSidebarOpen:s.leftSidebarOpen,
      toggleLeftSidebar:s.toggleLeftSidebar,
      rightSidebarOpen:s.rightSidebarOpen,
      toggleRightSidebar:s.toggleRightSidebar,
      sidebarView: s.sidebarView, 
      setSidebarView: s.setSidebarView
  }));
  const setSubscription = useNotifications(s=>s.setSubscription)    

  async function checkAuth() {
    const authStatus = await checkIsAuthenticated();
    console.log('isAuthenticated?', authStatus)
    if (!authStatus){
      console.log('navigating to login')
      navigate({ 
        to: '/signIn',
        search: {
            redirect: location.href,
          }
        })  
      } 
  }    
    
  useEffect(() => {
    checkAuth();
      
    console.log('user info', user_info)
    try{
      subscribeUser().then(subscription => {
        console.log('Subscription object:', subscription);
        setSubscription(subscription.endpoint, subscription.keys.p256dh, subscription.keys.auth)
        // Subscribe API call go here
        const JWT = useAuth.getState().token
        
        if (!user_info?.notification_subscriptions?.find(sub=>sub.endpoint==subscription.endpoint)){
          fetchData({
            method:'POST', 
            resource:'notifications', 
            token:JWT, 
            body: {
              endpoint: subscription.endpoint,
              keys: {
                p256dh: subscription.keys.p256dh, 
                auth: subscription.keys.auth
              }
            }
          })
        } else {
          console.log('Subscription already exists in user object')
        }
      }).catch(error => {
        console.log('Service Worker Subscription Failed', error);
      });
    } catch (error) {
      console.log(error)
    }
    
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'NEW_NOTIFICATION') {
          if (!notifications.find(n=>n.notification_id==event.data.notification_id)){
            // Update your application state to display the notification
            console.log("Adding new notification to queue", event.data)
            addNotification(event.data); // This could be a state setter or a dispatch
          }
        }
      });
    }
  }, []);    

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>   
      <FluxDataProvider>
        <NavBar>
          
          <div className={`App ${sidebarView==null?'':'open'} auth ${isMobile?'mobile':''}`}         
            style={{
              position: 'absolute',
              top:`${TOP_BAR_HEIGHT}px`,
              left: 0,
              width: '100vw',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
      
            <div style={{width:"100%", height:'100%'}} ref={ref} className="height-ref-div" />
            <ErrorBoundary boundaryName='authenticated-route'>    
                <Outlet />               
            </ErrorBoundary>
            
          <SideBar isMobile={isMobile} />
          </div>

        </NavBar>
      </FluxDataProvider>          
    </LocalizationProvider>        
  )
}