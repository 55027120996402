import { enqueueSnackbar, useSnackbar } from "notistack";
import { useAuth, useFleetData, useGroups, useLatestTrips, useLeaderboard, useNotifications, useUser, useVesselList, zuPage } from "./zustore";
import { useQuery } from "@tanstack/react-query";
import { LoadingBar } from "loadingBar/loadingBar";
import { fetchData } from "./QueryHandler";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import superjson from 'superjson';
import { format_date_string } from "utils/utilityMethods";
import { determineStatus } from "utils/determine-status";

const env = process.env.REACT_APP_ENV;
const TOP_BAR_HEIGHT = 40;

const getLocalData = (key, defaultValue)=>{
    const data = localStorage.getItem(key)
    return data===null?JSON.stringify(defaultValue):data
};
  
function makeVesselListUnique(combinedData){
    const uniqueVessels = combinedData.reduce((acc, item) => {
      const vesselId = item.vessel_id;
      const latestMessageDate = new Date(item.latest_message);
      
      if (!acc[vesselId] || new Date(acc[vesselId].latest_message) < latestMessageDate) {
        acc[vesselId] = item;
      }
      
      return acc;
    }, {});
    
    return Object.values(uniqueVessels);
}

export const FluxDataProvider = ({children}) => {
    const [hasCheckedCache, setHasCheckedCache] = useState(false);
    const { fleetData, updateFleetData } = useFleetData();
    const {vesselList, setVesselList } = useVesselList();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {leaderboard, updateLeaderboard} = useLeaderboard()
    const token = useAuth(s=>s.token)
    const notifications = useNotifications(s=>s.notifications)
    const addNotification = useNotifications(s=>s.addNotification)
    const { user_info, setUser } = useUser(state=>({user_info:state.user, setUser:state.setUser}))
    const JWT = useAuth.getState().token
    const [activeBoats, setActiveBoats] = useState(false)
    const updateGroups = useGroups(s=>s.updateGroups)
    const {latestTrips, updateLatestTrips} = useLatestTrips()
    const { page } = zuPage(s=>({ page:s.page }));

    useEffect(()=>{
        if (!hasCheckedCache && user_info){
            console.groupCollapsed('Local Data merger');
            if (!user_info){
                console.groupEnd();
                return
            }
            const localFleetData = superjson.parse(getLocalData(`fleet-${env}-${user_info?.user_id}`, []));
            let mergedFleetData = {...localFleetData}
            if (localFleetData) {
            console.log(fleetData, localFleetData)
            // check if the data in local storage is newer than the data in the zustore
            if (Object.keys(fleetData).length>=0 || Object.keys(localFleetData).length==0){
                mergedFleetData = {...fleetData, ...mergedFleetData}
            }
            updateFleetData(mergedFleetData)    
            } 
            
            const localVesselInfo = superjson.parse(getLocalData(`vessel-info-${env}-${user_info?.user_id}`, {}));
            console.log('localVesselInfo', localVesselInfo)
            setHasCheckedCache(true)
            console.groupEnd();
            if (localVesselInfo) {
                console.log('found local vessel data', vesselList, localVesselInfo, mergedFleetData?.['fd550513-d026-4d34-9783-0876d52a7123'])
                const combinedData = [...vesselList, ...localVesselInfo]      
                const uniqueVesselsArray = makeVesselListUnique(combinedData);
                console.log(uniqueVesselsArray.length)
                
                let updatedVesselList = {}; // add in the info from latest data
                Array.from(uniqueVesselsArray).forEach((vessel, index) => (
                    updatedVesselList[vessel.vessel_id] = { 
                    ...vessel,
                    latest_message: mergedFleetData[vessel.vessel_id] ? format_date_string(mergedFleetData[vessel.vessel_id].time) : null,
                    status: determineStatus(mergedFleetData[vessel.vessel_id])
                    }
                ));
                updatedVesselList = Array.from(new Set(Object.values(updatedVesselList)))
                console.log(updatedVesselList)
                setVesselList(updatedVesselList)
            } else {
                console.log('exiting Local Data merger')
            }
        }
    }, [user_info])

    function getLatest(data, default_days=30){
        let startTime = dayjs(Date.now()).subtract(default_days, 'day').valueOf().toString();
        if ( Object.keys(data).length>0 ){
          // console.log(data, Object.values(data).map(v=>Date.parse(v.time)), Object.values(data).map(v=>Date.parse(v.time)).filter(t=>t!=NaN).sort((a,b) => a-b))
          startTime = Object.values(data).map(v=>Date.parse(v.time)).filter(t=>t!=NaN).sort((a,b) => a-b)
          startTime = startTime[startTime.length-1]
          
          const d = new Date;
          startTime = startTime - parseInt(d.getTimezoneOffset()*60*1000) + 1
        }
        
        return startTime
    }
    
    // Fetch User Info
    const userQuery = useQuery({
        queryKey: ['users'],
        queryFn: async () => {
            const { status, body } = await fetchData({ method: 'GET', resource: 'users', token: JWT });
            if (status !== 200) throw new Error('Failed to fetch user data');
            const data = body
            return data
        },
        retry: 3,
    onSuccess: (data) => {
            setUser(data);
            if (data?.invites !== null && data.invites !== null && Object.keys(data.invites)?.length>0) {
            enqueueSnackbar("User info updated: New Invite found!", {variant:'Invite', autoHideDuration:10000, showExpand:true, invites:data.invites})
            }
        },
    });
    
    // Fetch Vessel Info
    const vesselInfoQuery = useQuery({
        queryKey: ['vessel-info'],
        queryFn: async () => {
            const { status, body } = await fetchData({ method: 'GET', resource: 'vessel-info', token: JWT });
            if (status !== 200) throw new Error('Failed to fetch vessel info');
            const data = body
            return data
        },
        enabled: !!JWT,  // Only fetch if JWT is available
        retry: 3,
    onSuccess: (data) => {
            console.log(env, user_info)
            const localVesselData = superjson.parse(getLocalData(`vessel-info-${env}-${user_info?.user_id}`, {}));
            if (localVesselData) {
            // console.log(body, localVesselData)
            const combinedData = [...data, ...localVesselData]      
            const uniqueVesselsArray = makeVesselListUnique(combinedData);
            const updatedVesselList = uniqueVesselsArray.map((vessel) => ({
                ...vessel,
                latest_message: (fleetData[vessel.vessel_id] ? format_date_string(fleetData[vessel.vessel_id].time) : null) || vessel.latest_message,
                status: determineStatus(fleetData?.[vessel.vessel_id]),
                })
            );
            // console.log('root loader - vessel-info', updatedVesselList)
            setVesselList(updatedVesselList);
            return updatedVesselList
            } else {
            setVesselList(data)
            return data
            }
        },
    });
    
    // Fetch Nodes Info
    const nodesQuery = useQuery({
        queryKey: ['nodes'],
        queryFn: async () => {
            const { status, body } = await fetchData({ method: 'GET', resource: 'nodes', token: JWT });
            if (status !== 200) throw new Error('Failed to fetch nodes');
            const data = body
            return data
        },
        enabled: !!JWT,  // Only fetch if JWT is available
        retry: 3,
    onSuccess: (data) => {
            updateGroups(data)
        },
    });    
    
    const fleetDataQuery = useQuery({
        queryKey: ['fleet-data'],
        queryFn: async () => {
            const JWT = useAuth.getState().token
            // console.log('fleet query', vesselList, fleetData)
            const vessels = vesselList ? vesselList.flatMap((vessel) => (vessel.vessel_id)) : [];
            let startTime = getLatest(fleetData)
    
            let {status, body, type} = await fetchData({
              method:'GET', 
              resource:'vessel-data/latest', 
              token:JWT, 
              params: {
                start_time:startTime,
                vessel_id:vessels
              }
            })
            if (status===204) throw new Error('vessel-data/latest: No data returned')
            ;
            if (Object.keys(body).length===0) throw new Error('No data returned')
            if (body==='User is not authorized to access any vessels') throw new Error('User is not authorized to access any vessels')
            return body;
          },
          enabled: vesselList.length>0 && token!=='' && token!=null && (token??null)!==null,
          staleTime: 1000*10, // 10 seconds
          refetchInterval: (page==='FleetOverview'&&activeBoats)?1000*10:1000*60*10, // refetch every 30 seconds
        retry: 3,
        onSuccess: (data) => {
            // Handle side effects after a successful fetch
            if (data){
              // console.log('GET vessel-data/latest returned data')
              if (Object.keys(fleetData).filter(k=>k!='message').length>0){
                data = {...fleetData, ...data}
              } else {
                console.log('No previous fleet data found')
              }
              let oldStartTime = getLatest(fleetData)
              let newStartTime = getLatest(data)
              
              updateFleetData(data)
              setActiveBoats(true)
              // console.log('Fleet Data', Object.keys(data).length)
      
              localStorage.setItem(`fleet-${env}-${user_info.user_id}`, superjson.stringify(data))
              
              let updatedVesselList = {};
              Array.from(vesselList).forEach((vessel, index) => (
              updatedVesselList[vessel.vessel_id] = { 
                  ...vessel,
                  latest_message: data[vessel.vessel_id] ? format_date_string(data[vessel.vessel_id].time) : null,
                  status: determineStatus(data[vessel.vessel_id])
              }));
      
              updatedVesselList = Array.from(new Set(Object.values(updatedVesselList)))
              // console.log(updatedVesselList, vesselList, updatedVesselList.length==vesselList.length)
              setVesselList(updatedVesselList)
              
              // console.log({cache_key:`vessel-info-${env}-${user_info.user_id}`, user_info})
              localStorage.setItem(`vessel-info-${env}-${user_info.user_id}`, superjson.stringify(updatedVesselList))
              if (oldStartTime !== newStartTime){
                enqueueSnackbar(`Vessel data updated: ${Object.keys(data).length} vessels found`, {variant:'QueryInfo'})
              }
            } else {
              console.groupCollapsed('fetchFleetData, data not defined');
              console.log(data)
              console.groupEnd();
            }
        },
        onError: (error) => {
            console.warn("Error fetching fleet data:", error);
            // setActiveBoats(false)
        }
    })

    const leaderboardQuery = useQuery({
        queryKey: ['leaderboard'],
        queryFn: async () => {
            const {status, body, type} = await fetchData({ method: 'GET', resource: 'trips/leaderboard', token: token, params: null });
            if (status===204) throw new Error('No data returned')
            if (status===403) throw new Error('403: Unauthorized')
            
            const data = body
            // console.log(data)
            if (data==='') throw new Error('No data returned')
            return data;
        },
        enabled: true,
        refetchInterval: 1000 * 60 * 15, // 30 seconds
        retry: 2,
        onSuccess: (data) => {
          if (data) {            
            console.groupCollapsed('leaderboard query');            
            console.table(data);  
            console.table(vesselList) 
            // const updatedLeaderboard = [...leaderboard, ...data]
            // const newData = data.filter(item => !leaderboard.some(existingItem => existingItem.vessel_id === item.vessel_id)); // Filter out any items that already exist in the existing data
            // const mergedData = [...leaderboard, ...newData]
            
            data = data.map(v=>{
                let vessel = vesselList.find(vessel=> vessel.vessel_id===v.vessel_id) || {vessel_name:v.vessel_id, battery:12}                
                return ({ ...v, name:vessel?.vessel_name, battery:vessel.battery })
                }
            )
            console.table(data)
            updateLeaderboard(data)
            console.groupEnd();
          }
        }
    });

    const latestTripsQuery = useQuery({
        queryKey: ['FleetTrips'],
        queryFn: async () => {
            let startTime = getLatest(latestTrips, 7)
            const params = {
                startTime: startTime
            };
            const {status, body, type} = await fetchData({ method: 'GET', resource: 'trips', token: token, params: params });
          if (status===204) throw new Error('No data returned')
          if (status===403) throw new Error('403: Unauthorized')
          
          const data = body
          // console.log(data)
          if (data==='') throw new Error('No data returned')
          return data;
        },
        enabled: true,
        refetchInterval: 1000 * 60 * 15, // 30 seconds
        retry: 2,
        onSuccess: (data) => {
          if (data) {            
            console.groupCollapsed('fleet trip viewer query');            
            console.log(data);  
            console.log(vesselList) 
            // data = Object.values(data).reduce((acc, next) => acc.concat(next), [])
            // const updatedLeaderboard = [...leaderboard, ...data]
            // const newData = data.filter(item => !leaderboard.some(existingItem => existingItem.vessel_id === item.vessel_id)); // Filter out any items that already exist in the existing data
            // const mergedData = [...leaderboard, ...newData]
            
            data = data.map(trip=>{
                let vessel = vesselList.find(vessel=> vessel.vessel_id===trip.vessel_id)
                if (!vessel) {
                    console.log(trip.vessel_id)
                    vessel = {vessel_name:trip.vessel_id, battery:12}
                }
                if (typeof(vessel.battery)==String){
                    console.log(vessel)
                }
                return ({
                    ...trip, 
                    name:vessel?.vessel_name, 
                    battery:vessel.battery,
                    
                })
                }
            )
            console.table(data.slice(1,10), data.length)
            updateLatestTrips([...data, ...latestTrips])
            console.groupEnd();
          }
        }
      });

    // Handle loading states
    if (userQuery.isLoading || vesselInfoQuery.isLoading || nodesQuery.isLoading) {
        return <LoadingBar />
    }

    // Handle errors
    if (userQuery.error || vesselInfoQuery.error || nodesQuery.error) {
        return <div>Error fetching data</div>;
    }

    return (
        <>
            {children}
        </>
    )
}