export function determineStatus(msg) {
    if (!msg) {
        return { text: "Loading", color: "var(--light-grey-3)", priority: 0 };
    }
  
    if (!msg || typeof msg !== "object") {
        return { text: "Unknown", color: "var(--light-grey-3)", priority: 0 };
    }
  
    const timeDiff = Date.now() - msg.sample_time;
  
    if (timeDiff > 1800000) {
        return { text: 'Offline', color: "var(--light-grey-3)", priority: 0 };
    }
  
    if (msg.on_water && msg.telemetry_data?.inst_speed > 2) {
        return { text: "On Water", color: "var(--light-grey-1)", priority: 4 };
    }
  
    if ((msg?.ecu_data?.[0]?.system_state===5 || msg.motor_data?.[0]?.system_state === 5) ) { //&& (msg.obc_data?.[0]?.obc_status ?? null) === null
        return { text: "Active", color: "#00B9B8", priority: 7 };
    }
  
    if ((msg?.ecu_data?.[0]?.system_state!==5&&msg.motor_data?.[0]?.system_state !== 5) && msg.telemetry_data?.inst_speed > 2) {
      return { text: "In Tow", color: "var(--light-grey-1)", priority: 4 };
    }
  
    if ((msg.motor_data?.[0]?.system_state ?? null) === null && (msg.obc_data?.[0]?.charge_power ?? null) !== null) {
        return { text: "Charging", color: msg.obc_data[0].charge_power >= 0.1 ? "#28E27B" : "#D29827", priority:  msg.obc_data[0].charge_power >= 0.1 ? 6 : 5 };
    }
  
    if ((msg.motor_data?.[0]?.system_state ?? null) === 16) {
        return { text: 'Fault', color: 'var(--red-alt)', priority: 3 };
    }
  
    if ((msg.motor_data?.[0]?.system_state ?? null) === null && (msg.obc_data?.[0]?.obc_status ?? null) === null) {
        return { text: "Off", color: "var(--dark-grey-1)", priority: 2 };
    }
  
    return { text: "Unknown", color: "var(--light-grey-3)", priority: 1 };
  };
  