import { createFileRoute, Link, Outlet, redirect, useLocation, useNavigate } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { LocalizationProvider } from '@mui/x-date-pickers';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Badge, Box, IconButton, Menu, MenuItem, SwipeableDrawer, Typography, useMediaQuery } from '@mui/material';
import { Home, Logout, Menu as MenuIcon, Groups, ArrowBack, ArrowForward, ChevronRight, RouteOutlined, AccountCircleOutlined, DirectionsBoatOutlined, HomeOutlined, NotificationsOutlined, GroupOutlined} from '@mui/icons-material';
import { Network } from 'lucide-react';
import ErrorBoundary from "../utils/ErrorBoundary";
import logo from "../images/01_Flux Only.svg";
import { useAuth, useFleetData, useGroups, useNotifications, useSelectedNodeStore, useUser, useVessel, useVesselList, zuPage } from '../app/zustore';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useMemo, useState } from 'react';


export const NavBar = ({children}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const notifications = useNotifications(s=>s.notifications)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [menu_type, setMenuType] = useState(null)
    const navigate = useNavigate();
    const location = useLocation();
    const vessel_id = useVessel(s=>s.vessel_id)
    const vessel_name = useVessel(s=>s.vessel_name)
    const selectedNode = useSelectedNodeStore(s=>s.selectedNode)
    const { page, setPage, leftSidebarOpen, toggleLeftSidebar, rightSidebarOpen, toggleRightSidebar, sidebarView, setSidebarView } = zuPage(s=>({
        page:s.page, 
        setPage:s.setPage, 
        leftSidebarOpen:s.leftSidebarOpen,
        toggleLeftSidebar:s.toggleLeftSidebar,
        rightSidebarOpen:s.rightSidebarOpen,
        toggleRightSidebar:s.toggleRightSidebar,
        sidebarView: s.sidebarView, 
        setSidebarView: s.setSidebarView
    }));

    const activeIcon = useMemo(()=>{
        console.log("authenticated route", location, location.pathname.split('/')[1])
        return location.pathname.split('/')[1]
      }, [location])

    const handleOpenMenu = (event, type) => {
        setMenuType(type)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (item) => { // trigger navigation
        console.log('menu clicked for ', item)
        setAnchorEl(null);
        switch (item) {
          case "Live": 
            if (vessel_id == null) {
              navigate({
                to: '/vessels/select',
                params: { vessel_id: vessel_id }
              });
              break;
            }
            navigate({ 
              to: `/vessels/${vessel_id}/`, 
              params: { vessel_id: vessel_id }, 
              mask: { to: `/vessels/${vessel_name}/` }
            });
            break;
      
          case "Analyze": 
            if (vessel_id == null) {
              navigate({
                to: '/vessels/select',
                params: { vessel_id: vessel_id }
              });
              break;
            }
            navigate({ 
              to: `/vessels/${vessel_id}/analyze`, 
              params: { vessel_id: vessel_id }, 
              mask: { to: `/vessels/${vessel_name}/analyze` }
            });
            break;
      
          case "Manage": 
            if (vessel_id == null) {
              navigate({
                to: '/vessels/select',
                params: { vessel_id: vessel_id }
              });
              break;
            }
            navigate({ 
              to: `/vessels/${vessel_id}/manage`, 
              params: { vessel_id: vessel_id }, 
              mask: { to: `/vessels/${vessel_name}/manage` }
            });
            break;
      
          case "Select": 
            if (menu_type=='vessels'){
              navigate({ 
                to: '/vessels/select', 
                params: { vessel_id: vessel_id }
              });
            } else if (menu_type=='trips'){
              navigate({ 
                to: '/trips/select', 
                params: { vessel_id: vessel_id }
              });
            }
            break;
      
          case "Members": 
            navigate({ to: `/groups/members`, });
            break;
          
          case "Delete": 
            navigate({ to: `/groups/delete`, });
            break;
      
          case "Move": 
            navigate({ to: `/groups/move`, });
            break;
      
          case "Add Vessel": 
            navigate({ to: `/groups/new-vessel`, });
            break;
      
          case "Create": 
            navigate({ to: `/groups/new`, });
            break;
      
          case "Rename": 
            navigate({ to: `/groups/rename`, });
            break;
      
          case "Feed": 
            navigate({ to: '/trips/' });
            break;
      
          case "View Trip": 
            navigate({ to: '' });
            break;
      
          case "Home": 
            navigate({ to: '/home/' });
            break;
      
          default:
            console.log('Unknown menu item:', item);
        }
    };    

    const handleHomeButtonClick = () => {
    console.log(location.pathname)  
    if (location.pathname=='/home'){
        if (vessel_id==null){
        navigate({ to: '/vessels/select' })
        } else {
        navigate({ to: '/vessels/', params:{ vessel_id:vessel_id} })
        }
    } else {
        navigate({ to: '/home' })
    }
    
    };
  
    const menuItems = useMemo(() => {
    switch (menu_type) {
        case 'vessels':
        return [ "Live",  "Analyze",  "Manage", "Select"  ]
        case "groups": 
        return [  "Members",  "Move", "Add Vessel", "Create", "Rename", "Delete"  ]
        case "trips": 
        return [  "Feed", "Select"]
        case "home": 
        return [  "Home" ]
        default:
        return null
    }
    }, [menu_type])
  
    const header = ()=>{
        if (isMobile){
          return (
            <div className='app-header'>          
              
            <div className='header-left-group'>
              <Tooltip title="Notifications" arrow>
                <IconButton className={`header-icon ${(sidebarView=='notifications' && rightSidebarOpen) ? 'active': ''}`} 
                onClick={()=>{
                  setSidebarView(rightSidebarOpen ? null : 'notifications');
                  if (isMobile){
                    toggleRightSidebar(!rightSidebarOpen)
                  }
                }}>
                  <NotificationsOutlined color='black'/>
                </IconButton>
                </Tooltip>
            </div>
              
              <div 
                onClick={handleHomeButtonClick}
              >
                <Link to='/'><img src={logo} className="App-logo fm" alt="logo" /></Link>
              </div>
        
              <div className='header-right-group'>
                
                <Tooltip title="Profile" arrow>
                  <IconButton className={`header-icon ${(sidebarView=='profile' && rightSidebarOpen) ? 'active': ''}`} 
                  onClick={()=>{
                    setSidebarView(rightSidebarOpen ? null : 'profile');
                    if (isMobile){
                      toggleRightSidebar(!rightSidebarOpen)
                    }
                  }}>
                    <AccountCircleOutlined color='black'/>
                  </IconButton>
                </Tooltip>
              </div>
        
          </div>     
          )
        } else {
          return (
            <div className='app-header'>          
              
            <div className='header-left-group'>
                
            <IconButton
              className={`header-icon ${activeIcon=='home' ? 'active' : ''}`} 
              onClick={(event)=>handleClose('Home')} 
            >
              <HomeOutlined />
            </IconButton>
  
            <IconButton
              className={`header-icon ${activeIcon=='vessels' ? 'active' : ''}`} 
              onClick={(event)=>handleOpenMenu(event, 'vessels')} 
            >
              <DirectionsBoatOutlined />
            </IconButton>
  
            <IconButton
              className={`header-icon ${activeIcon=='trips' ? 'active' : ''}`}
              onClick={(event)=>handleOpenMenu(event, 'trips')} 
            >
              <RouteOutlined />
            </IconButton>
  
            <IconButton
              className={`header-icon ${activeIcon=='groups' ? 'active' : ''}`}
              onClick={(event)=>handleOpenMenu(event, 'groups')} 
            >
              <Network />  
            </IconButton>
  
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onMouseLeave={handleClose}
                className='header-menu'
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
            >{
              menuItems && 
              menuItems.map((item, index)=>(
                <MenuItem onClick={()=>handleClose(item)} key={index}>{item}</MenuItem>
              ))
            }
          </Menu>
  
              
            </div>
              
              <div 
                onClick={handleHomeButtonClick}
              >
                <Link to='/'><img src={logo} className="App-logo fm" alt="logo" /></Link>
              </div>
        
              <div className='header-right-group'>
              <Tooltip title="Profile" arrow>
                <IconButton className={`header-icon ${(sidebarView=='profile' && rightSidebarOpen) ? 'active': ''}`}  onClick={()=>{setSidebarView(sidebarView=='profile' ? null : 'profile')}}>
                  <AccountCircleOutlined color='black' sx={{color:'#a3a9b1'}}/>
                </IconButton>
              </Tooltip>
                
              <Tooltip title="Notifications" arrow>
                <IconButton className={`header-icon ${(sidebarView=='notifications' && rightSidebarOpen) ? 'active': ''}`}  onClick={()=>{setSidebarView(sidebarView=='notifications' ? null : 'notifications')}}>
                  <Badge 
                    badgeContent={notifications.length} 
                    color='primary' 
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}>
                    <NotificationsOutlined color='black' sx={{color:'#a3a9b1'}}/>
                  </Badge>
                </IconButton>
                </Tooltip>
              </div>
        
          </div>     
          )
        }
      }
  
      const footer = ()=>{
        return (
          <div className='app-footer'>          
              
            <div className='footer-icon-group'>
              <Tooltip title="Home" arrow>
                <Link className={`header-icon ${activeIcon=='home' ? 'active' : ''}`}
                  to={ location.pathname=='/home' ? (vessel_id==null ? '/vessels/select' : '/vessels/') : '/home' } params={{ vessel_id:vessel_id }}>
                  <HomeOutlined />
                </Link>
              </Tooltip>
              
              <Tooltip title="Vessel" arrow>
                <Link className={`header-icon ${activeIcon=='vessels' ? 'active' : ''}`}
                  to={vessel_id==null ? '/vessels/select' : '/vessels/'} params={{vessel_id: vessel_id}}> 
                  <DirectionsBoatOutlined />
                </Link>
              </Tooltip>      
  
              <Tooltip title="Trips" arrow>
                <Link className={`header-icon ${activeIcon=='trips' ? 'active' : ''}`}
                  to={'/trips'}
                  params={{node_id: selectedNode?.id}} 
                  mask={{to:`/trips/`}}
                  >
                  <RouteOutlined />
                </Link>
              </Tooltip>
  
              <Tooltip title="Groups" arrow>
                <Link className={`header-icon ${activeIcon=='groups' ? 'active' : ''}`}
                  to={'/groups/$node_id/members'}
                  params={{node_id: selectedNode?.id}} 
                  mask={{to:`/groups/${selectedNode?.name.replace(' ', '-')}/manage`}}
                  >
                  <GroupOutlined />
                </Link>
              </Tooltip>
              
              
            </div>
          </div>     
        )
      }

      return (
        <>
            {
                header()
            }
            
            {children}
            
            {
                isMobile && 
                footer()
            }
        </>
      )
}