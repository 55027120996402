import { Grid, } from '@mui/material';
import { format_date } from '../../utils/utilityMethods';
import { useState, useEffect, useRef, useMemo } from "react";
import ErrorBoundary from '../../utils/ErrorBoundary';
// import '../../pageLayout.css'
import { useFleetData, useVessel, zuPage, useVesselList, useSelect, useLeaderboard, useGroups } from '../../app/zustore';
import BatteryIndicator from './battery';
import { useMediaQuery } from '@mui/material';
import { LoadingBar } from '../../loadingBar/loadingBar';
import { MiniUI } from './miniUI';
import { MiniCharging } from './miniCharging';
import { Link, useNavigate } from '@tanstack/react-router';
import { numberWithCommas } from '../leaderboard';

export default function VesselOverview({ vessel, index, isBud }) {
    const fleetData = useFleetData((state) => state.fleetData);
    const setVessel = useVessel(state=>state.setVessel)
    const leaderboard = useLeaderboard(s=>s.leaderboard)
    const isMobile = useMediaQuery('(max-width:600px)');
    const {setTab, setPageTab} = zuPage(state=>({setTab:state.setTab, setPageTab:state.setPageTab}))
    const vesselList = useVesselList((state) => state.vesselList);
    const setSelected = useSelect(state=>state.setSelected);
    const groups = useGroups(s=>s.groups)
    const navigate = useNavigate();

    const handleVesselButtonClick = (requestedTab)=>{
        // setVesselToUpdate(vessel)
        setSelected(vesselList.findIndex(v=>v.vessel_id===vessel.vessel_id))
        setVessel(vessel.vessel_id, vessel.vessel_name)
        if (vessel.vessel_name.search('Dyno')>=0){
            setPageTab('Tabs', 4)
        } else {
            setPageTab('Tabs', requestedTab)
        }   
    }

    const owner = useMemo(() => {
        const node_id = Object.keys(vessel.node)[0];
        const group = groups.find(g=>g.node_id==node_id)
        return group
    }, [groups, vessel])

    const format_date = (vessel_id)=>{
        const d = new Date;
        let startTime = Date.parse(fleetData[vessel_id].time) - (d.getTimezoneOffset()*60*1000)
        return new Date(startTime).toLocaleString()
    }

    const handleHaptics = ()=>{
        if (navigator.vibrate) {
            navigator.vibrate(100); // Vibrate for 200ms
        }
    }    

    const vesselStats = useMemo(()=>{
        // console.log(groups, vessel)
        const nodesContainingVessel = groups
            .filter( g => g.vessel_ids!==null)
            .filter( g => g?.vessel_ids.find(row=>row==vessel.vessel_ids) !== undefined )
            .sort((a,b) => b.node_rank - a.node_rank)
        if (nodesContainingVessel.length==0) {
            // console.log({nodesContainingVessel})
        }
        if (isBud){
            // console.log(vessel.vessel_name, vessel.vessel_id, nodesContainingVessel)
            return {odometer:0, motor_hours:0, num_trips:0, group_name:nodesContainingVessel[0]?.node_name}
        }
        const stats = leaderboard.find(row=>row.vessel_id==vessel.vessel_id)
        // console.log(groups)
        
        // console.table(stats)
        if (stats == undefined){
            return {odometer:0, motor_hours:0, num_trips:0, group_name:nodesContainingVessel[0]?.node_name}
        }
        return {...stats, group_name:nodesContainingVessel[0]?.node_name}
        return {...stats, group_name:nodesContainingVessel[0]?.node_name}
      },[leaderboard, vessel.vessel_id, isBud])

    const content = ()=>{
        if (isBud) {
            return (
                <div className='vesselCard-contentArea'>
                    <div className='vesselCard-speed'>
                        <a className='value'>{Math.round(
                            Math.min(
                                Math.max(
                                    0, 
                                    fleetData[vessel.vessel_id]?.telemetry_data?.inst_speed || 0) || 0,
                                100)
                            )}
                        </a>
                        <a className='unit'>MPH</a>
                    </div>
                </div>
            )
        } else if (vessel?.status?.text === "Charging"){
            let soc = (fleetData[vessel.vessel_id]?.battery_data?.battery_soc>95?fleetData[vessel.vessel_id]?.battery_data?.battery_soc:Math.round(fleetData[vessel.vessel_id]?.battery_data?.battery_soc)) || 0;
            let cp = fleetData[vessel.vessel_id].obc_data?.[0].charge_power || 0;
            let cc = fleetData[vessel.vessel_id].obc_data?.[0].charge_current || 0;
            let cv = fleetData[vessel.vessel_id].obc_data?.[0].charge_voltage || 0;

            return (
                <div className='vesselCard-contentArea'>
                    <MiniCharging index={index} soc={soc} cp={cp} cc={cc} cv={cv} />       
                </div>
            )
        } else {
            let mph = fleetData[vessel.vessel_id]?.telemetry_data?.inst_speed ?? '0';
            let hp = fleetData[vessel.vessel_id]?.motor_data?.[0].horse_power || 0;
            let soc = Math.round(fleetData[vessel.vessel_id]?.battery_data?.battery_soc) || 0;
            let dir = Math.abs(fleetData[vessel.vessel_id]?.motor_data?.[0].velocity) > 100 ? 'F' : 'N';
            let system_on = (fleetData[vessel.vessel_id]?.motor_data?.[0]?.system_state == 5
                || fleetData[vessel.vessel_id]?.ecu_data?.[0]?.system_state == 5)
                && (vessel?.status?.text !== 'Offline')
            return (
                <div className='vesselCard-contentArea'>
                    <MiniUI index={index} mph={mph} hp={hp} soc={soc} dir={dir} system_on={system_on} />
                </div>
            )
        }
    }

    const TopInfoBar = ()=>{
        const statusText = () => {
            return (
            <div className='vesselCard-status'>    
                <a style={{color:vessel?.status?.color ?? '#fff'}}>{vessel?.status?.text ?? 'Unknown'}</a>
            </div>
            )
        }        

        return (
            <div className={`vesselCard-topInfo ${isMobile}`}>
                <h3>{owner?.node_name?.replace("'s_Default_Group", "").replace("'s_default_group", "")}</h3>
                <h1>{vessel.vessel_name.replace("fm-","")}</h1>
                            
                {statusText()}

                <div className='vesselCard-activity'>    
                    {/* <a>Latest activity:</a> */}
                    <a>{format_date(vessel.vessel_id)}</a>
                </div>

                {!isBud &&
                <div className='vessel-card-stats'>
                    <div className='vesselCard-trips'>    
                        <a className='value'>{Math.round(vesselStats?.num_trips || 0)}</a>
                        <a className='unit'>trips</a>
                    </div>
                    
                    <div className='vesselCard-odometer'>    
                        <a className='value'>{numberWithCommas(Math.round(vesselStats?.odometer) || 0)}</a>
                        <a className='unit'>mi</a>
                    </div>

                    <div className='vesselCard-hours'>    
                        <a className='value'>{Math.round(vesselStats?.motor_hours || 0)}</a>
                        <a className='unit'>hrs</a>
                    </div>
                </div>
                }

                {/* <div className='vesselCard-location'>    
                    <a>Location:</a>
                    <a>{`(${Math.round(fleetData[vessel.vessel_id].telemetry_data.latitude * 100) / 100}, ${Math.round(fleetData[vessel.vessel_id].telemetry_data.longitude * 100) / 100})`}</a>
                </div> */}
            </div>
        )
        
    }

    const livePath = vessel.vessel_name.search('Dyno')>=0 ? 'dyno' : 'live'

    if (fleetData[vessel.vessel_id]) {
        // console.log(vessel)
        return (
            <div className='VesselCard' key={index}>

                <ErrorBoundary boundaryName={`vessel overview ${index}`} style={{ width: '100%' }}>
                    <div className={`flip-card `}
                        >
                        <div 
                            className={`vesselCard ${isBud && 'bud'}`}
                            onMouseEnter={() => {
                                document.body.style.cursor = 'pointer';
                            }}
                            onMouseLeave={() => {
                                // Restore the default cursor when leaving the tile
                                document.body.style.cursor = 'auto';
                            }}
                        >
                            {TopInfoBar()}   

                            {content()}

                            <div className='vesselCard-actionArea'>
                                <button onClick={handleHaptics} className='fm-btn'>
                                    <Link 
                                        to={"/vessels/"+livePath} 
                                        search={{vessel_id: vessel.vessel_id,}}>Live</Link>
                                </button>
                                <button onClick={handleHaptics} className='fm-btn'>
                                    <Link 
                                        to="/vessels/analyze" 
                                        search={{vessel_id: vessel.vessel_id,}}>Analyze</Link>
                                </button>
                                <button onClick={handleHaptics} className='fm-btn'>
                                    <Link 
                                        to="/vessels/manage/edit" 
                                        search={{vessel_id: vessel.vessel_id,}}>Manage</Link>
                                </button>
                            </div>
                        
                        </div>
                        
                    </div>
                </ErrorBoundary>

            </div>
        )
    }
    else {
        return (
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2} xxl={2} key={index} style={{ width: '100%', margin: '1px 0px 0px 0px', padding:'1px' }}>

                <ErrorBoundary boundaryName={`vessel overview ${index}`} style={{ width: '100%' }}>

                    <div className={`flip-card `}>
                        <div 
                            className={`vesselCard ${isBud && 'bud'} loading`}
                            onMouseEnter={() => {
                                document.body.style.cursor = 'pointer';
                            }}
                            onMouseLeave={() => {
                                // Restore the default cursor when leaving the tile
                                document.body.style.cursor = 'auto';
                            }}
                        >
                            <h1>{vessel.vessel_name}</h1>
                            <LoadingBar home={false} className='vesselCard-loadingBar' style={{gridArea:'loading'}}/>
                        </div>
                    </div>
                </ErrorBoundary>
            </Grid>
        )
    }
}
