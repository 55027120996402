import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAuth, useGroups, useUser, useVesselList } from 'app/zustore';
import { useEffect, useState } from 'react';
import { FleetTreeView } from 'fleet/FleetTreeView';
import { fetchData } from 'app/QueryHandler';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/vessels/manage/move')({
    component: MoveVessel
})

export default function MoveVessel({  }) {
    const vesselList = useVesselList(state=>state.vesselList);
    const { vessel_id } = Route.useSearch()
    const vessel = vesselList.find(v=>v.vessel_id==vessel_id) || vesselList.find(v=>v.vessel_name==vessel_id)
    const vessel_name = vessel?.vessel_name || '';
    const user = useUser(state => state.user);
    const {groups, updateGroups} = useGroups((state) => ({ groups: state.groups, updateGroups:state.updateGroups}))
    const sourceNode = groups.find(g=>g.node_id==(Object.keys(vessel?.node)?.[0]));
    const [destinationNode, setDestinationNode] = useState(sourceNode.node_id);

    useEffect(() => {
        console.log({vessel, sourceNode, destinationNode})
    }, []);

    const handleConfirmMove = async () => {
    if (!sourceNode || !destinationNode || sourceNode.id===destinationNode.id) return;
    if (window.confirm(`Are you sure you want to move ${vessel_name} from ${sourceNode.node_name} to ${destinationNode.name}?\n\nThis will remove access from users who are not members of the destination group`)){
        console.log('Moving', sourceNode.node_name, 'to', destinationNode.name)
        let requestBody = {
            vessel_id: vessel_id,
            from_node_id: sourceNode.id,
            to_node_id: destinationNode.id
        }
        const JWT = useAuth.getState().token
        let {status, body} = await fetchData({method:'PUT', resource:'vessel-info/move', body:requestBody, token:JWT})
        console.log({status, body})
        if (status===200){
            console.log('Delete successful', body)
            // window.location.reload()
            // let updated_node = {...groups.find(group=>group.node_id===sourceNode.id), parent_id:destinationNode.id}
            // let new_parent = groups.find(group=>group.node_id===destinationNode.id)
            //     new_parent = {...new_parent, children:[...new_parent.children, updated_node]}
            // let old_parent = groups.find(group=>group.node_id===sourceNode.parentId)
            //     old_parent = {...new_parent, children:[...new_parent.children, updated_node]}
            // let updated_groups = [...groups, updated_node, new_parent, old_parent]
            // console.log(groups, updated_groups)
            // updateGroups(body)
        } else {
            console.log('Move failed')
        }

    } else {
        console.log('Move canceled')
    }
  }

  const button_disabled = ()=>{
    if (
        !sourceNode 
        || !destinationNode 
        || sourceNode.id===destinationNode.id
        || sourceNode.parentId===destinationNode.id
        || !sourceNode.can_write
        || !destinationNode.can_write
    ) return true;
    else {
        return false;
    }
  }

    return (
    <DndProvider backend={HTML5Backend}>
    <div className='manage-page-2-col'>
        <h2 className='move-text'>Move {vessel_name} from <span>{sourceNode?.node_name}</span> to <span></span> <span>{destinationNode?.name}</span></h2>
        
        <div className='move-node-trees'>
        {user?.nodes &&
            <FleetTreeView isSidebar={false} setSelectedNode={()=>{}} initial_selection={sourceNode.node_id} variant={'from'} isStatic={true}/>
        }   
        
        {user?.nodes &&
            <FleetTreeView isSidebar={false} setSelectedNode={setDestinationNode} initial_selection={destinationNode?.id||destinationNode} variant={'to'}/>
        }   
        </div>

        <button className='confirm' onClick={handleConfirmMove} disabled={button_disabled()}>Move</button>
    </div>
    </DndProvider>
)}