import { useState, useEffect, useMemo } from "react";
import { LocationBox } from "../../../vessel/location_box";
import dayjs from 'dayjs';
import GaugeGrid from "../../../vessel/GaugeGrid";
import { fetchData } from "../../../app/QueryHandler";
import { useSelect, useVessel, useVesselList, useLiveData, useAuth, useFleetData, useLeaderboard } from "../../../app/zustore";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import { CompressedCSVToDataFrame } from "../../../utils/utilityMethods";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from 'notistack';
import { processAnalysisData } from "../../../utils/dataProcessing";
import { LoadingBar } from "../../../loadingBar/loadingBar";
// import '../../../pageLayout.css'
import { FaultCodeCounter } from "../../../vessel/faultCodes";
import { VesselActivity } from "../../../vessel/vessel-activity-widget";
import { VesselQuickSelector } from "../../../vessel/VesselQuickSelector";
import VesselOverview from "../../../fleet/VesselCard/VesselOverview";
import { Expander } from "../../../fleet/Expander";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useMediaQuery } from "@mui/material";

export const Route = createFileRoute('/_authenticated/vessels/live')({
  // Or in a component
  // loader: liveDataLoader,
  component: LiveData,
  pendingComponent: ({ params })=><LoadingBar className='vessels/live'/>,
  beforeLoad: ({ search, location })=>{
    if (location=='/vessels/select'){
      return
    }
    console.log('Live Data before load', search.vessel_id, location)
    const vessel_id = search.vessel_id
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    // if (!uuidRegex.test(vessel_id)) throw redirect({
    //   to: '/_authenticated/_vessels',
    //   params: {}
    // });
    // Parser function to determine if the param is a UUID or a name
    const vesselList = useVesselList.getState().vesselList;
    const vessel_name = vesselList.find(v=>v.vessel_id==vessel_id)?.vessel_name || 'NOT_FOUND';
    console.log('live data before load:', vessel_name)
    if (vessel_name.search('Dyno')>=0){
      console.log('Redirecting to the dyno page!')
        throw redirect({
            to: '/vessels/dyno',
            search: {vessel_id: vessel_id}
          })
    }
  }
})

export default function LiveData() {
  const { vessel_id } = Route.useSearch() || useVessel.getState().vessel_id;
  const vessel = useVesselList(s=>s.vesselList).find(vessel => vessel.vessel_id === vessel_id);
  const vesselList = useVesselList((state) => state.vesselList);
  const { liveData, setLiveData } = useLiveData();
  const {leaderboard, updateLeaderboard} = useLeaderboard()
  const fleetData = useFleetData(s=>s.fleetData);
  const { enqueueSnackbar } = useSnackbar();
  const [activeBoat, setActiveBoat] = useState(false)
  const { selected, setSelected } = useSelect();
  const [hasReturnedEmpty, setHasReturnedEmpty] = useState(false)
  const isMobile = useMediaQuery('(max-width:600px)');

  const vesselStats = useMemo(()=>{
    const tempV_ID = (vessel_id==='4b4abeb3-c837-473d-8c67-c64b3c1a4759')?'cypress-cay':vessel_id //remove after reupload
    const stats = leaderboard?.find(row=>row.vessel_id==tempV_ID)
    // console.table(stats)
    return stats
  },[leaderboard, vessel_id])

  useEffect(()=>{
    const fetchLeaderboard = async () => {

      const params = {
        vessel_id: vessel_id
      }
      const JWT = useAuth.getState().token
      const {status, body, type} = await fetchData({ method: 'GET', resource: 'trips/leaderboard', token: JWT, params: params });
      if (status===204) {
        console.warn('No data returned')
        return
      } else if (status===403) {
        console.warn('403: Unauthorized')
        return
      } else if (status===500) {
        console.warn('500: Internal Server Error')
        return
      }
          
      let data = body.map(v=>{
          let vessel = vesselList.find(vessel=> vessel.vessel_id===v.vessel_id) || {vessel_name:v.vessel_id, battery:12}                
          return ({ ...v, name:vessel?.vessel_name, battery:vessel.battery })
        }
      )

      function makeVesselListUnique(combinedData){
        const uniqueVessels = combinedData.reduce((acc, item) => {
          const vesselId = item.vessel_id;
          const latestMessageDate = new Date(item.latest_trip_start_time);
          
          if (!acc[vesselId] || new Date(acc[vesselId].latest_trip_start_time) < latestMessageDate) {
            acc[vesselId] = item;
          }
          
          return acc;
        }, {});
        
        return Object.values(uniqueVessels);
      }

      console.log(data)
      const updatedLeaderboard = makeVesselListUnique([...data, ...leaderboard])
      updateLeaderboard(updatedLeaderboard)         
      
    }

    console.log(leaderboard)
    if (!leaderboard || leaderboard.find(row=>row.vessel_id==vessel_id)==undefined){
      console.log('no data found in the leaderboard for his vessel')
      fetchLeaderboard()
    }    
  }, [])

  const queryKey = ['live-data', vessel_id];

  const liveDataQuery = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      let startTime = dayjs(Date.now()).startOf('day').valueOf().toString();
      const token = useAuth.getState().token;
      if (liveData[vessel_id]) {
        const sampletime = liveData[vessel_id][ liveData[vessel_id].length - 1 ].time;
        const d = new Date();
        startTime = Date.parse(sampletime) - (d.getTimezoneOffset()*60*1000) + 1;
        // console.log(sampletime, startTime)        
      }
      const params = {
        vessel_id: vessel_id,
        start_time: startTime
      }
      const {status, body, type} = await fetchData({ method: 'GET', resource: 'vessel-data/daily', token: token, params: params });
      if (status===204) throw new Error('No data returned')
      // console.log(body)
      const data = body
      // console.log(data)
      if (data==='' && !liveData[vessel_id]) throw new Error('No data returned')
      return data;
    },
    enabled: (vessel_id !== null) && (queryKey[1] === vessel_id) && (vessel !== undefined) && (vessel !== null),
    refetchInterval: activeBoat?1000 * 10:1000*60*5, // 10 seconds when active, 5min otherwise
    retry: 2,
    onSuccess: (data) => {
      // const ;
      // console.log(data)
      if (data.length > 10) {
        setActiveBoat(true)
        const df = CompressedCSVToDataFrame(data);
        console.groupCollapsed('live-data query', vessel_id);
        console.log(vessel, df.length)
        enqueueSnackbar(`${vessel.vessel_name} updated: ${df.length} rows retrieved`, { variant: 'info' });
        console.table(df.slice(0,10));

        console.log("setting current data to:", df[df.length - 1]);
        console.groupEnd();
        const existingData = liveData[vessel_id] || [];

        const newData = df.filter(item => !existingData.some(existingItem => existingItem.sample_time === item.sample_time)); // Filter out any items that already exist in the existing data
        const uniqueLiveData = [];
        const mergedData = [...existingData, ...newData].sort((a, b) => {
          return new Date(a.sample_time) - new Date(b.sample_time);
        }).reduce((acc, item) => {
          const vesselId = item.vessel_id;
          const time = item.sample_time
          // const latestMessageDate = new Date(item.latest_message);
          
          if (!acc[time]) {
            acc[time] = 1;
            uniqueLiveData.push(item)
          }
          
          return acc;
        }, {}); // Merge the existing data with the new data and sort by sample_time

        // const vessel = vesselList.find(vessel => vessel.vessel_id === vessel_id);
        
        const processedData = processAnalysisData(uniqueLiveData, vessel);
        if (!liveData[vessel_id] || processedData.length > liveData[vessel_id].length) {
          setLiveData(vessel_id, processedData);
          // setDataSource(vessel.isParticleDevice?'bud':'pi')
        }
      }
    },
    onError: (error) => {
      console.log(`No data for ${vessel.vessel_name}, throttling back the query interval`)
      setActiveBoat(false)
      setHasReturnedEmpty(true)
    }
  });

  const ShowMiniUI = ()=>{
    if (vessel.isParticleDevice){
      return null
    }
    // console.log('showing mini ui on live page')
    return (
      
      <VesselOverview 
        key={0} 
        vessel={vessel} 
        index={0}
        isBud={vessel.isParticleDevice}
        style={{ height: '100%', width: '100%', minWidth:'250px', minHeight:'250px' }}
        />
    )
  }

  const content = ()=>{
    // const setTab = zuPage(state=>({setTab:state.setTab}));
    if ((!selected || selected===null) && !vessel_id) {
      return <h1>Please Select a Vessel</h1>
    } 

    if (liveDataQuery.isError && liveData[vessel_id] && liveData[vessel_id]?.length===0) {
      return(
      <div>
        <h1>Error</h1>
        <span>Error: {liveDataQuery.error.message}</span>
      </div>
      )
    } 
    else if (liveDataQuery.isLoading && !liveData[vessel_id] && !hasReturnedEmpty) {
      return (
      <div>
        {vessel_id !== '' ? <span style={{ fontSize: '28px', color: 'white', marginRight: "10px", paddingRight: "10px" }}>{vessel?.vessel_name || ''}</span>
        : <span style={{ fontSize: '28px', color: 'black', marginRight: "10px", paddingRight: "10px" }}>Select a Vessel</span>}
        <h1>Loading data...</h1>
        <LoadingBar />
      </div>)
    } 
    else if (fleetData[vessel_id] ) {
      if (vessel.vessel_name.search('Dyno')>=0){
        return (
          null
        )
      } else {
      return (
        <div style={{width:'100%', display:'flex', flexDirection:'column', alignContent:'center', rowGap:'3px'}}>
          <ErrorBoundary boundaryName="vessel-header">
            {fleetData[vessel_id]
                ? <h3 className='LiveDataHeader'>
                  {vessel.vessel_name}
                </h3>
                : <h3>Loading</h3>
              }
          </ErrorBoundary>

          <ErrorBoundary boundaryName="vessel-summary">
            <div className="vessel-summary-area">
            {vesselStats != undefined &&
              <div className="vessel-info-area">                

                {
                  liveData[vessel_id] 
                  ?
                  <div className="vessel-info-widget">
                    <div>
                      <span>{liveData?.[vessel_id].length} Messages received today</span>
                    </div>
                    
                </div>
                : <h4 className='LiveDataLatest' >No messages yet</h4>
                }

              </div>
            }

              {ShowMiniUI()}
              
              {(!isMobile && false) &&
                <VesselActivity isMobile={isMobile} vessel_id={vessel_id}/>
              }
            </div>
          </ErrorBoundary>          
          
          {
            liveData?.[vessel_id] &&
            <ErrorBoundary>
              <Expander isMobile={isMobile} title='Live map' startCollapsed={true}>
              {fleetData[vessel_id] != null && liveData?.[vessel_id].filter((item) => item['telemetry_data.latitude'] !== null).length>0
                ? <LocationBox
                isQuery={false}
                className="locationBox"
                style={{ paddingTop: "10px", marginTop: "20px" }}
                />
                : <span>GPS Data came back as null</span>
              }
              </Expander>
            </ErrorBoundary>
          }

          {
            liveData?.[vessel_id] &&
            
            <ErrorBoundary boundaryName="Live Data Gauge Grid">
              <Expander isMobile={isMobile} title='Gauge Grid' startCollapsed={false}>
                <GaugeGrid isMobile={isMobile} data={liveData?.[vessel_id]} vessel_id={vessel_id}/>
              </Expander>
            </ErrorBoundary>
          }
          
          {
            liveData?.[vessel_id] &&
          <ErrorBoundary boundaryName="Live Data Fault Counter">
            <Expander isMobile={isMobile} title='Alerts' startCollapsed={false}>
              <FaultCodeCounter data={liveData[vessel_id]}/>
            </Expander>
          </ErrorBoundary>
          }

        </div>
      )
    }
    };
  }

  return (
    <div
    className="vessel-page-outer">

      {(vessel_id!=null && vessel_id!=='select') && content()}

      <div style={{marginBottom:'10px', width:'100%'}}>
      <Expander isMobile={isMobile} title='Vessel selector' startCollapsed={vessel_id!='select'}>
        <VesselQuickSelector />
      </Expander>
      </div>

    </div>
  );
}

